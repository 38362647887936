body {
  margin: 0;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiDrawer-paper {
  background-color: transparent !important;
  width: inherit;
}

div#root {
  background-color: #eee;
}

button > .MuiButton-label {
  text-transform: none !important;
}

/* Dialogs should dim the background (gray so it works on light + dark mode) */
.MuiDialog-container {
  background: rgba(125, 125, 125, 0.3);
}

/* Our custom dialogs have their own styling */
.MuiPaper-root[aria-labelledby="custom-dialog"],
.MuiPaper-root[aria-labelledby="confirmation-dialog"] {
  background: transparent;
  box-shadow: none;
}

.MuiPaper-root {
  margin: auto;
}

.MuiTab-root {
  min-width: 80px !important;
}
